
    .modal_content{
    display:flex;
    &>div{
        width: 50%;
    }
    #modal_chart_shui{
        height: 350px;
    }
    .modal_left{
        margin: 20px 0 0 0;
        ul{
            li{
                padding: 0 40px;
                display:flex;
                margin: 0 0 25px 0;
                div:nth-child(1){
                    width: 150px;
                    color:#999;
                }
                div:nth-child(2){
                    color:#333;
                }
            }
        }
    }
}
