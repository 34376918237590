
	@import "../../variables.less";
	@import "../../resource/common.less";

	@currentWidth: 650px;
	@attachWidth: 150px;
	.eleUse {
		width: 24%;
		height: 64px;
        // @baseColor2: rgba(red(@baseColor), green(@baseColor), blue(@baseColor), 0.7);
        // background: linear-gradient(to right, @baseColor, @baseColor2);
        background: url("../../assets/img/item3.png");background-repeat: no-repeat;
		border-radius: 8px;
        color:#fff;
		display: flex;
		justify-content: space-around;
		align-items: center;
		flex-direction: column;
		.ele-item {
			text-align: center;
			align-items: center;
			width: 100%;
			height: 50%;
			display: flex;
			justify-content: center;
			p {
				font-size: 14px;
				text-align: center;
    			width: 50%;
			}
		}
		.ele-change {
			text-align: center;
			width: 100%;
			height: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			p {
				font-size: 12px;
			}
			.red {
				color: #FD4848;
                font-weight: 600;
				font-size: 16px;
			}
			img {
				width: 16px;
				height: 16px;
				margin-left: 6px;
			}
		}
		.green {
            font-weight: 600;
			font-size: 16px;
			color: #26cc63;
		}
		.orange {
			font-size: 16px;
			color: #EF9F24;
            font-weight: 600;
		}
		&:nth-child(2) {
			&:before {
				content: "";
				width: 1px;
				border-left: 1px solid #FFF;
				height: 80%;
				top: 10%;
				left: -25%;
				position: absolute;
				display: block;
			}

			&:after {
				content: "";
				width: 1px;
				border-right: 1px solid #FFF;
				height: 80%;
				top: 10%;
				right: -25%;
				position: absolute;
				display: block;
			}
		}
	}

	.row {
		width: 100%;
		height: 80px;
		display: flex;
		flex-direction: row;
		margin-bottom: @bigSpace;
		justify-content: space-around;
		&:last-child {
			flex-grow: 1;
		}
	}
	.row-echart {
		height: 40%;
		width: 100%;
        display:flex;    justify-content: space-around;
        &>div{
            width: calc(50% - 15px);
            border-radius: 4px;
            background:@contentBg;
            box-shadow: 0 0 10px 1px @boxshadow;
            height: 100%
        }
		.echart-any {
			width: 100%;
			height: calc(100% - 60px);
		}
	}
	.table-water {
		width: 100%;
		height: 85%;
		overflow: auto;
	}
	.item {
		width: calc(100% - 20px);
		height: calc(43% - 10px);
        border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        margin: 10px;
	}
    .table_top{
        border-radius: 4px;
        background:@contentBg;
        box-shadow: 0 0 10px 1px @boxshadow;
        margin: 10px;
    }
    .device_status_icon_bg{
        width: 30px;
    }
