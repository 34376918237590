.mainContent {
  width: 650px;
  position: absolute;
  right: 0;
  bottom: 20px;
  top: 100px;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 4px;
  pointer-events: all;
  transition: right 2s ease;
  border-radius: 4px 0 0 4px;
}
.mainContent .head {
  line-height: 32px;
  font-size: 18px;
  flex-shrink: 0;
  color: #FFF;
}
.mainContent .head .attach {
  width: 150px;
  float: right;
}
.mainContent .blockTitle {
  font-size: 14px;
  margin-left: 6px;
  border-bottom: 1px solid #FFF;
  color: white;
}
.left_click_btn {
  position: absolute;
  top: 48%;
  left: -18px;
  background: var(--themeColor);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px 0px;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
}
.left_click_btn img {
  width: 18px;
  transform: rotate(180deg);
}
.left_click_btn_active {
  transition: all 2s ease;
}
.left_click_btn_active img {
  transform: rotate(0deg);
}
.mainContent2,
.index {
  position: absolute;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  width: 750px;
  background: rgba(255, 255, 255, 0.9);
  pointer-events: all;
  transition: right 1s ease;
}
.mainContent2 .mian_title,
.index .mian_title {
  font-size: 18px;
  width: 100%;
  padding: 10px 10px;
  display: flex;
  font-weight: 600;
  position: relative;
  margin: 0 0 0 10px;
  line-height: 18px;
}
.mainContent2 .mian_title:before,
.index .mian_title:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 11px;
  background: #4261ED;
  left: 1px;
}
.mainContent2 .gary_line,
.index .gary_line {
  width: calc(100% - 20px);
  height: 2px;
  position: relative;
  left: 10px;
  bottom: 0;
  background-color: rgb(247 247 250);
}
.mainContent2 .gary_line::after,
.index .gary_line::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0px;
  background: #e0e2e3;
  right: 0;
}
.mainContent2 .gary_line::before,
.index .gary_line::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  top: 0;
  background: #e0e2e3;
  left: 0;
}
.eleUse {
  width: 24%;
  height: 64px;
  background: url("../../assets/img/item3.png");
  background-repeat: no-repeat;
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.eleUse .ele-item {
  text-align: center;
  align-items: center;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
}
.eleUse .ele-item p {
  font-size: 14px;
  text-align: center;
  width: 50%;
}
.eleUse .ele-change {
  text-align: center;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eleUse .ele-change p {
  font-size: 12px;
}
.eleUse .ele-change .red {
  color: #FD4848;
  font-weight: 600;
  font-size: 16px;
}
.eleUse .ele-change img {
  width: 16px;
  height: 16px;
  margin-left: 6px;
}
.eleUse .green {
  font-weight: 600;
  font-size: 16px;
  color: #26cc63;
}
.eleUse .orange {
  font-size: 16px;
  color: #EF9F24;
  font-weight: 600;
}
.eleUse:nth-child(2):before {
  content: "";
  width: 1px;
  border-left: 1px solid #FFF;
  height: 80%;
  top: 10%;
  left: -25%;
  position: absolute;
  display: block;
}
.eleUse:nth-child(2):after {
  content: "";
  width: 1px;
  border-right: 1px solid #FFF;
  height: 80%;
  top: 10%;
  right: -25%;
  position: absolute;
  display: block;
}
.row {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
  justify-content: space-around;
}
.row:last-child {
  flex-grow: 1;
}
.row-echart {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.row-echart > div {
  width: calc(50% - 15px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  height: 100%;
}
.row-echart .echart-any {
  width: 100%;
  height: calc(100% - 60px);
}
.table-water {
  width: 100%;
  height: 85%;
  overflow: auto;
}
.item {
  width: calc(100% - 20px);
  height: calc(43% - 10px);
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.table_top {
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0 0 10px 1px rgba(66, 97, 237, 0.1);
  margin: 10px;
}
.device_status_icon_bg {
  width: 30px;
}
